<template>
    <svg version="1.1" id="_x30_." xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve" :width="size" :fill="color">
        <g>
            <g> 
                <path class="st0" d="M3.6,54.5c39.5,25.9,79,51.9,118.4,78c2.3,1.5,3.9,1.5,6.1,0c8.6-5.8,17.3-11.5,26-17.2
                    c30.5-20.1,61-40.3,91.6-60.2c3.7-2.4,3.8-4.7,1.9-7.9c-3.4-5.7-8.5-8-15.1-8c-35.8,0.1-71.5,0-107.3,0c-35.9,0-71.8,0-107.8,0
                    c-7,0-12.2,2.6-15.5,8.9C0.7,50.7,0.7,52.6,3.6,54.5z"/>
                <path class="st0" d="M246.5,195.8c-30.8-19.4-61.5-38.8-92.2-58.3c-2.1-1.3-3.5-1.4-5.6,0c-5.9,4.1-12,7.8-17.8,11.9
                    c-4,2.7-7.7,2.7-11.7-0.1c-5.9-4-11.8-8-17.9-11.7c-1.3-0.8-3.8-0.9-5-0.1c-31,19.4-61.8,39-92.7,58.4c-2.5,1.5-2.9,3-1.7,5.6
                    c3.1,6.7,8.4,9.5,15.6,9.5c35.9,0,71.8,0,107.7,0c7.8,0,15.6,0,23.4,0c28.1,0,56.2,0,84.3,0c7,0,12.2-2.7,15.3-9.1
                    C249.4,199.1,249.2,197.5,246.5,195.8z"/>
                <path class="st0" d="M0.5,71c0,36.4,0,72,0,108.3c28.1-17.7,55.6-35.1,83.9-53C56.2,107.7,28.5,89.5,0.5,71z"/>
                <path class="st0" d="M165.7,126.3c28.2,17.8,55.8,35.3,83.8,53c0-36.3,0-72,0-108.2C221.3,89.6,193.7,107.8,165.7,126.3z"/>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'iconMessage',
    props:{
    size: {
        type: Number,
        default: 20,
    },
    color: {
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    
</style>